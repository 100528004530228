<template>
  <v-card>
    <v-toolbar class="page-primary-card-header">
      <v-list-item dark>
        <v-list-item-avatar color="primary lighten-2">
          <v-icon>{{card_header_props.header.icon}}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="headline">{{card_header_props.header.headLine}}</v-list-item-title>
          <v-list-item-subtitle>{{card_header_props.header.subTitle}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-btn
        @click="card_header_props.helper.page_helper = !card_header_props.helper.page_helper"
        icon
        dark
      >
        <v-icon>info</v-icon>
      </v-btn>
    </v-toolbar>

    <v-dialog v-model="card_header_props.helper.page_helper" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>{{$t("_common.How_can_I_use_this_form")}}</v-card-title>

        <v-card-text>{{$t("_add_topics._info.content")}}</v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="card_header_props.helper.page_helper = false">{{$t("_common.OK")}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container class="mx-0 col-12">
      <!-- <v-layout>
        <v-flex xs4 class="my-2 pr-2">
          <v-card xs12 width="100%">
          </v-card>
        </v-flex>
        <v-flex xs8 class="my-2">
          <v-card xs12 width="100%">
          </v-card>
        </v-flex>
      </v-layout>-->

      <v-container class="m-0 p-0 col-12">
        <v-row>
          <v-col cols="12" sm="12" md="4">
            <v-card width="100%">
              <v-sheet class="pa-4">
                <v-text-field
                  v-model="search"
                  :label= 'this.$t("_add_topics.Search_Topic")'
                  flat
                  hide-details
                  clearable
                  clear-icon="mdi-close-circle-outline"
                ></v-text-field>
              </v-sheet>
              <v-card style="overflow:auto;" height="380px" flat>
                <v-treeview
                  ref="treeViewTopics"
                  v-bind="selectedTreeData"
                  :items="treeTopicData"
                  :search="search"
                  item-children="Children"
                  activatable
                  multiple="false"
                  selection-type="leaf"
                  item-key="Id"
                  dense
                  color="accent"
                  item-text="Title"
                  style="min-width: fit-content;"
                  @update:active="onSelectedTopic"
                ></v-treeview>
              </v-card>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="removeTopic" :disabled="!selected">{{$t("_common.Remove")}}</v-btn>
                <v-btn color="success" @click="updateTopic" :disabled="!selected">{{$t("_common.Edit")}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="8">
            <v-card width="100%">
              <v-form ref="topicFormElement">
                <v-card-text style="height: 460px;">
                  <div id="topicTreeDiv" style="vertical-align: middle">
                    <v-flex>
                      <v-text-field
                        v-model="topicForm.Name"
                        :label='this.$t("_add_topics.Enter_a_topic_name")'
                        :rules="validations.Name"
                        :disabled="formDisabled"
                      />
                    </v-flex>
                    <v-flex>
                      <v-textarea
                        v-model="topicForm.Description"
                        :label='this.$t("_add_topics.Enter_a_description")'
                        :disabled="formDisabled"
                      />
                    </v-flex>
                    <v-flex>
                      <v-file-input
                        v-model="topicForm.ImageUrl"
                        :label='this.$t("_add_topics.Select_a_Image")'
                        accept="image/*"
                        :disabled="formDisabled"
                      />
                    </v-flex>
                    <v-img
                      v-if="imageUrlView"
                      :src="imageUrlView"
                      aspect-ratio="1"
                      max-height="135px"
                      max-width="240"
                    ></v-img>
                  </div>
                </v-card-text>
              </v-form>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="resetForm" color="warning" :disabled="formDisabled">{{$t("_common.Reset")}}</v-btn>
                <v-btn @click="addTopic" color="success" :disabled="formDisabled">{{$t("_common.Save")}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

    </v-container>
  </v-card>
</template>

<script>
import { VSelectTree, VTree } from "vue-tree-halower";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VuetifyConfirm from "vuetify-confirm";
import Vue from "vue";

require("../../assets/css/neo.css");

Vue.use(VuetifyConfirm, {
  buttonTrueText: "Accept",
  buttonFalseText: "Discard",
  color: "warning",
  icon: "warning",
  title: "Warning",
  width: 350,
  property: "$confirm"
});

export default {
  data() {
    return {
      treeTopicData: [],
      selected: null,
      validations: {
        Name: this.$goc.validate.create().required(),
        Description: this.$goc.validate
          .create()
          .required()
          .min(10),
        ImageUrl: this.$goc.validate.create().required()
      },
      topicForm: {
        Name: "",
        ParentId: 0,
        Description: null,
        ImageUrl: null,
        Type: 0
      },
      imageUrlView: null,
      selectedTreeData: null,
      formDisabled: false,
      treeTopicDataModel: [],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.2
      },
      dialog: false,
      search: null,
      card_header_props: {
        header: {
          headLine: this.$t("_add_topics.Add_Topics"),
          subTitle: this.$t("_add_topics.Add_Topics_Text"),
          icon: "mdi-plus-circle-outline"
        },
        helper: {
          absolute: true,
          opacity: 0.8,
          overlay: false,
          page_helper: false
        }
      }
    };
  },
  methods: {
    getTopics() {
      this.$goc.request.get(this.$enums.API.Lexicon.Topic, response => {
        this.treeTopicData = response.Result.Topics;
        // this.topicForm = {};
      });
    },

    onSelectedTopic(id) {
      if (id && id.length > 0) {
        this.selectedTreeData = this.$refs.treeViewTopics.nodes[id[0]].item;

        if (this.selectedTreeData && this.selectedTreeData.Type === 1) {
          this.formDisabled = true;
        } else {
          this.formDisabled = false;
        }

        this.resetForm();
        this.selected = id[0];
      } else {
        this.selected = null;
        this.resetForm();
      }
    },
    removeTopic() {
      if (this.selected !== 0) {
        this.$goc.notify.confirm(
          {
            title: this.$t("_common.AreYouSure"),
            message:this.$t("_add_topics.Selected_topic_is_going_to_be_deleted")
          },
          res => {
            if (res) {
              let item = this.selected;

              this.$goc.request.delete(
                this.$enums.API.Lexicon.Topic + item,
                () => {
                  this.getTopics();
                  this.resetForm();
                  this.selected = null;
                  this.$goc.notify.success({
                    title: this.$t('_common.Success'),
                    message: this.$t('_add_topics.Topic_successfully_deleted'),
                  });

                }
              );
            }
          }
        );
      } else {
        this.$goc.notify.warning({ message: this.$t("_add_topics.Please_select_a_topic") });
      }
    },
    updateTopic() {
      if (this.selected !== 0) {
        this.formDisabled = false;
        let item = this.selected;
        let topicDataModel = this.$refs.treeViewTopics.nodes[item].item;
        this.topicForm.Name = topicDataModel.Title;
        this.topicForm.Id = topicDataModel.Id;
        // this.topicForm.ImageUrl = topicDataModel.ImageUrl;
        this.topicForm.Description = topicDataModel.Description;
        this.topicForm.ParentId = topicDataModel.ParentId;
        this.topicForm.Type = topicDataModel.Type;
        this.imageUrlView = topicDataModel.ImageUrl;
        this.treeTopicDataModel = topicDataModel;
      } else {
        this.$goc.notify.warning({ message: this.$t("_add_topics.Please_select_a_topic") });
      }
    },
    createTopic() {
      let formData = this.topicForm;

      if (this.topicForm.Id) {
        //Update
        this.$goc.request.patch(this.$enums.API.Lexicon.Topic, formData, () => {
          this.resetForm();
          this.getTopics();
          this.$goc.notify.success({
            title: this.$t('_common.Success'),
            message: this.$t('_add_topics.Topic_successfully_updated'),
          });
        });
      } else {
        //Insert
        if (this.topicForm.Name && this.topicForm.Name.length > 0) {
          formData.ParentId = this.selected;
          this.$goc.request.post(
            this.$enums.API.Lexicon.Topic,
            formData,
            () => {
              this.resetForm();
              this.getTopics();
              this.$goc.notify.success({
                title: this.$t('_common.Success'),
                message: this.$t('_add_topics.Topic_successfully_added'),
              });
            }
          );
        } else {
          this.$goc.notify.warning({ message: this.$t("_add_topics.Warning")});
        }
      }
    },
    createTopicImageAndSave() {
      let formData = new FormData();

      if (
        this.topicForm.ImageUrl &&
        this.topicForm.Name &&
        this.topicForm.Name.length > 0
      ) {
        formData.append("file", this.topicForm.ImageUrl);
        this.$goc.request.postMultipart(
          this.$enums.API.Images.Single,
          formData,
          response => {
            this.topicForm.ImageUrl = response.result.url;
            this.createTopic();
          }
        );
      } else {
        this.createTopic();
      }
    },
    addTopic() {
      if (this.topicForm.Id) {
        this.$goc.notify.confirm(
          {
            title: this.$t("_common.Update"),
            message:this.$t("_add_topics.Selected_topic_is_going_to_be_updated")
          },
          res => {
            if (res) {
              this.createTopicImageAndSave();
            }
          }
        );
      } else {
        this.createTopicImageAndSave();
      }
    },
    resetForm() {
      if (this.selectedTreeData && this.selectedTreeData.Type === 1) {
        this.formDisabled = true;
      }
      this.$refs.topicFormElement.reset();
      this.topicForm.Id = null;
      this.imageUrlView = null;
      this.topicForm.Name = null;
      this.topicForm.Description = null;
      this.topicForm.ParentId = null;
      this.topicForm.ImageUrl = null;
    }
  },
  components: {
    VTree,
    VSelectTree,
    VuePerfectScrollbar,
    VuetifyConfirm
  },
  mounted: function() {
    this.getTopics();
    this.$goc.setModule("AddTopic", this);
  },
  watch: {
    selected: function() {
      this.$emit("value-change", this.selected);
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/extraComponents/tree.scss";
</style>
